import React from "react";
import Modal from "components/Modal";
import { useDispatch } from "react-redux";
import { PATHS } from "router/config/paths";
import { useAppSelector } from "store";
import { hideSnackbar } from "store/snackbarSlice";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { IconClose } from "icons";

function getText(type: any) {
  switch (type) {
    case 402:
      return "Please upgrade your plan to use this feature.";
    case 409:
      return "Your design is currently being generated. Please wait until it’s complete before starting the next one.⏳";
    case 503:
      return "Our servers are currently too busy. Please try again later.";
    default:
      return "An error occurred.";
  }
}

const WarningSnackbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visible, type, message } = useAppSelector((state) => state.snackbar);
  const [isOn, setIsOn] = React.useState(false);

  if (!visible) return null;

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  function handleToggle() {
    // if (isOn) AnalyticsEvent("pricing_monthly_selected", "", "click");
    // else AnalyticsEvent("pricing_yearly_selected", "", "click");

    setIsOn((prev) => !prev);
  }

  return (
    <Modal onClose={handleClose} show={visible} bodyClassName="bg-bg-2 w-[600px]" className="!z-[9999]">
      <IconClose className="absolute cursor-pointer w-6 h-6 text-grey top-2 left-2" onClick={handleClose} />

      <div className="flex flex-col gap-10 p-6">
        <h2 className="text-h2 text-white text-center">{message ? message : getText(type)}</h2>
        {/* <div className="flex flex-col gap-2.5">
          <div className="flex items-center gap-[15px]">
            <h6 className={`text-headline1 !text-[11px] uppercase ${!isOn ? "text-white" : "text-grey"}`}>Monthly</h6>
            <ToggleButton trackClassName="" thumbClassName="!bg-white" isOn={isOn} onToggle={handleToggle} />
            <h6 className={`text-headline1 !text-[11px] uppercase  ${isOn ? "text-white" : "text-grey"}`}>Yearly</h6>
            <span className="text-headline1 !text-[11px] uppercase text-green">20% discount</span>
          </div>
        </div>

        <h2 className="text-h2 text-white">Upgrade to 813081838013801 for more access</h2>

        <ul className="flex flex-col gap-3">1212</ul> */}

        {type === 402 && (
          <div className="flex flex-col gap-2">
            {/* <Button className="btn-primary">3333333</Button> */}
            <Button
              className="btn-primary"
              onClick={() => {
                navigate(PATHS.PRICING);
                handleClose();
              }}
            >
              View All Plans
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WarningSnackbar;
