import AWS from "aws-sdk";
import { Buffer } from "buffer";
import { IconWarning } from "icons";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";
import { EventUploadLimitError } from "utils/events";
import { v4 as uuidv4 } from "uuid";
interface IS3Upload {
  files: any[];
  folderName?: string;
  bucketName?: string;
}
interface IS3SingleUpload {
  file: any;
  folderName?: string;
  bucketName?: string;
}

const useS3 = () => {
  const dispatch = useDispatch();

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const s3 = new AWS.S3();

  async function uploadFiles({ files, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3Upload) {
    const sizeLimit = Number(process.env.REACT_APP_UPLOAD_LIMIT) * 1000000;
    const overSizedFiles = files.filter((file) => file.size > sizeLimit);
    const rightSizedFiles = files.filter((file) => file.size < sizeLimit);
    if (overSizedFiles.length > 0) {
      dispatch(setSnackbar({ message: "File size should be less than 10MB", icon: IconWarning }));

      const pathname = window.location.pathname;
      const cleanPathname = pathname.startsWith("/") ? pathname.substring(1) : pathname;
      EventUploadLimitError(cleanPathname);
    }

    try {
      const uploadedFiles = await Promise.all(
        rightSizedFiles.map(async (file: any) => {
          const fileExtension = file?.name.split(".").pop(); // Extract file extension

          const params = {
            Bucket: bucketName,
            Key: folderName + uuidv4() + "." + fileExtension,
            Body: file,
            ACL: "public-read",
            ContentType: file?.type,
          };

          const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };

          return await s3.upload(params, options).promise();
        })
      );

      return uploadedFiles;
    } catch (error) {
      dispatch(setSnackbar({ message: "Something went wrong uploading files", icon: IconWarning }));

      return [];
    }
  }

  async function uploadFile({ files, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3Upload) {
    const sizeLimit = Number(process.env.REACT_APP_UPLOAD_LIMIT) * 1000000;
    const overSizedFiles = files.filter((file) => file.size > sizeLimit);
    if (overSizedFiles.length > 0) {
      dispatch(setSnackbar({ message: "File size should be less than 10MB", icon: IconWarning }));

      const pathname = window.location.pathname;
      const cleanPathname = pathname.startsWith("/") ? pathname.substring(1) : pathname;

      EventUploadLimitError(cleanPathname);

      return [];
    }

    const fileExtension = files[0]?.name.split(".").pop(); // Extract file extension

    try {
      const params = {
        Bucket: bucketName,
        Key: folderName + uuidv4() + "." + fileExtension, //UID gelecek
        Body: files[0],
        ACL: "public-read",
        ContentType: files[0]?.type,
      };
      const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };

      const res = await s3.upload(params, options).promise();

      return [res];
    } catch (error) {
      dispatch(setSnackbar({ message: "Something went wrong uploading the file", icon: IconWarning }));

      return [];
    }
  }

  async function uploadBase64({ file, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3SingleUpload) {
    try {
      const base64Data = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), "base64");

      const type = file.split(";")[0].split("/")[1];

      const params = {
        Bucket: bucketName,
        Key: folderName + "canvasItem_" + uuidv4(),
        Body: base64Data,
        ACL: "public-read",
        ContentEncoding: "base64", // required
        ContentType: `image/${type}`, // required. Notice the back ticks
      };
      const res = await s3.upload(params).promise();

      return [res];
    } catch (error) {
      dispatch(setSnackbar({ message: "Something went wrong uploading the base64 data", icon: IconWarning }));

      return [];
    }
  }

  async function handleUpload({ files, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3Upload) {
    if (files.length === 1) return uploadFile({ files, folderName, bucketName });
    else return uploadFiles({ files, folderName, bucketName });
  }

  return {
    uploadFile: uploadFile,
    uploadFiles: uploadFiles,
    handleUpload: handleUpload,
    uploadBase64: uploadBase64,
  };
};

export default useS3;
